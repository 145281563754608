import { Collapse, Tabs } from 'antd';
import NotFound from 'components/shared/NotFound';
import { StyledTabs } from 'components/tab';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { GreyRoundButton, InvertedRoundButton } from '@/design-system/buttons';

import CourseCard from '../shared/CourseCard';
import { StyledIntro } from '../shared/intro';

const { TabPane } = Tabs;

const { Panel } = Collapse;

const StyledCategories = styled.div`
  padding: 80px 72px;
  @media (min-width: 320px) and (max-width: 550px) {
    padding: 50px 16px;
  }
  .categories-actions {
    display: flex;
    justify-content: center;
    margin-top: 56px;
    &.no-mobile {
      @media (max-width: 550px) {
        display: none;
      }
    }
    &.no-tab {
      @media (min-width: 550px) and (max-width: 1024px) {
        display: none;
      }
    }
    a {
      margin-right: 24px;
      text-align: center;
    }
    @media (min-width: 320px) and (max-width: 550px) {
      //phone
      flex-direction: column;
      a {
        margin-bottom: 24px;
      }
    }
  }
`;

export const StyledTabss = styled(Tabs)`
  width: 100%;
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 16px;
    color: ${(props) => props.theme.fadedGrey};
    :hover {
      color: ${(props) => props.theme.primaryText};
      opacity: 0.9;
    }
  }
  .ant-tabs-nav-list {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10 spx;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    font-size: 16px;
    color: ${(props) => props.theme.primaryText};
  }
  .ant-tabs-ink-bar {
    background-color: ${(props) => props.theme.primaryLink};
  }
  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-bar {
    margin: 0 0 40px;
    border: none;
    @media (min-width: 320px) and (max-width: 550px) {
      margin: 0 0 30px;
    }
  }
  .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated,
  .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated {
    padding: 0 40px;
    margin-bottom: 40px;
    @media (min-width: 320px) and (max-width: 800px) {
      margin-bottom: 30px;
      padding: 0 16px;
    }
    @media (min-width: 800px) and (max-width: 1024px) {
      padding: 0 35px;
    }
  }
  .ant-tabs-nav-container {
    padding-left: 40px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 32px;
    @media (min-width: 320px) and (max-width: 800px) {
      overflow-x: inherit;
      padding-left: 20px;
    }
  }
  .ant-tabs-nav.ant-tabs-nav-animated > div {
    @media (min-width: 320px) and (max-width: 840px) {
      width: 100vw;
    }
  }
  .ant-tabs-nav.ant-tabs-nav-animated > div > div.ant-tabs-tab:first-child {
    @media (min-width: 320px) and (max-width: 550px) {
      margin-left: 80px;
    }
    @media (min-width: 550px) and (max-width: 1024px) {
      margin-left: 120px;
    }
  }
  .ant-tabs-tab-next-icon-target,
  .ant-tabs-tab-prev-icon-target {
    color: ${(props) => props.theme.primaryText};
    border-radius: 50%;
    padding: 3px;
    font-size: 12px;
  }
  div.ant-tabs-bar.ant-tabs-top-bar {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledCourseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 32px;
`;

const StyledCollapse = styled(Collapse)`
  @media (min-width: 1024px) {
    display: none;
  }
  &.ant-collapse {
    border: none;
  }
  &.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #ffffff;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: #1b222c;
    color: #ffffff;
  }
  .ant-collapse-content {
    background-color: #1b222c;
  }
`;
const StyledPanel = styled(Panel)``;

const TopCategories = ({ topCategories, topCourses }) => {
  const [activeCourses, setActiveCourses] = useState([]);
  const [activeCategory, setActiveCategory] = useState(topCategories[0]);

  useEffect(() => {
    if (activeCategory === 'all') {
      setActiveCourses(topCourses);
    } else {
      const filteredCourses = topCourses.filter((temp) =>
        temp.categories.some((category) => activeCategory._id === category._id)
      );
      setActiveCourses(filteredCourses);
    }
  }, [activeCategory, topCategories, topCourses]);

  const handleTabChange = (key) => {
    const selectedCategory = topCategories.filter((topCategory) => topCategory.url === key);
    if (selectedCategory && selectedCategory[0]) {
      setActiveCategory(selectedCategory[0]);
    } else {
      setActiveCategory({});
    }
  };

  return (
    <StyledCategories>
      <StyledIntro centered>
        <div className="main-title">Check Out Our Top Categories</div>
      </StyledIntro>
      <StyledCollapse
        accordion={true}
        defaultActiveKey={topCategories && topCategories[0] ? topCategories[0].url : ''}
        onChange={handleTabChange}>
        {topCategories &&
          topCategories.map((topCat) => (
            <StyledPanel header={topCat.name} key={topCat.url}>
              {activeCourses.length === 0 ? (
                <NotFound title="Oops. No course in category" showImage={false} />
              ) : (
                <>
                  <StyledCourseGrid>
                    {activeCourses.map((activeCourse) => (
                      <CourseCard course={activeCourse} key={activeCourse._id} centered />
                    ))}
                  </StyledCourseGrid>
                  <div className="categories-actions">
                    {activeCategory && (
                      <Link passHref href={`/categories/${activeCategory.url}`}>
                        <InvertedRoundButton bannerButton className="banner-btn" as="a">
                          View {activeCategory.name} Courses
                        </InvertedRoundButton>
                      </Link>
                    )}
                    <Link passHref href="/categories">
                      <GreyRoundButton bannerButton className="banner-btn" as="a">
                        View All Categories
                      </GreyRoundButton>
                    </Link>
                  </div>
                </>
              )}
            </StyledPanel>
          ))}
      </StyledCollapse>
      <StyledTabs
        className="no-tab no-mobile"
        defaultActiveKey={topCategories && topCategories[0] ? topCategories[0].url : ''}
        onChange={handleTabChange}
        animated={false}>
        {topCategories &&
          topCategories.map((topCat) => (
            <TabPane tab={topCat.name} key={topCat.url}>
              {activeCourses.length === 0 ? (
                <NotFound title="Oops. No course in category" />
              ) : (
                <StyledCourseGrid>
                  {activeCourses.map((activeCourse) => (
                    <CourseCard course={activeCourse} key={activeCourse._id} centered />
                  ))}
                </StyledCourseGrid>
              )}
            </TabPane>
          ))}
      </StyledTabs>
      <div className="categories-actions no-mobile no-tab">
        {activeCategory && (
          <Link passHref href={`/categories/${activeCategory.url}`}>
            <InvertedRoundButton bannerButton className="banner-btn" as="a">
              View {activeCategory.name} Courses
            </InvertedRoundButton>
          </Link>
        )}
        <Link passHref href="/categories">
          <GreyRoundButton bannerButton className="banner-btn" as="a">
            View All Categories
          </GreyRoundButton>
        </Link>
      </div>
    </StyledCategories>
  );
};

export default TopCategories;
