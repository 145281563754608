// usage -- truncate.apply(string, [11, true]);
export function truncate(n, useWordBoundary) {
  if (this.length <= n) {
    return this;
  }
  const subString = this.substr(0, n - 1);
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '...';
}

export const truncateText = (text, max = 25, ending = '...') => {
  if (!text || text === '') {
    return text;
  }
  return text.substr(0, max - 1) + (text.length > max ? ending : '');
};

export function secondsToHms(secs) {
  secs = Number(secs);
  const h = Math.floor(secs / 3600);
  const m = Math.floor((secs % 3600) / 60);
  const s = Math.floor((secs % 3600) % 60);

  const hDisplay = h > 0 ? h + 'h ' : '';
  const mDisplay = m > 0 ? m + 'm ' : '';
  const sDisplay = s > 0 ? s + 's ' : '';
  return hDisplay + mDisplay + sDisplay;
}

/**
 *
 * @param {*} isPaid is Bought Course
 * @param {*} isSubscribed is Subscribed
 * @param {*} courseOrLessonState
 */
export function accessCheck(isPaid, isSubscribed, courseOrLessonState) {
  if (Boolean(courseOrLessonState) == false) {
    return true;
  } else {
    if (Boolean(isPaid) || Boolean(isSubscribed)) {
      return true;
    }
    return false;
  }
}

export function timezoneToDate(tz) {
  if (!tz) return;
  const realDate = new Date(tz);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(realDate);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(realDate);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(realDate);
  return `${day}-${month}-${year}`;
}

/**
 * Formats number to 2 dp and uses the locale to determine the separator
 * @param amount
 * @param locale = 'en-US'
 * @returns string
 */
export const formatCurrency = (amount, locale = 'en-US', decimal = false) => {
  if (!amount) {
    return '';
  }

  // remove any non-numeric, non-period, non-comma character
  const cleanedNumber = amount.toString().replace(/[^0-9.,]+/g, '');
  return Number(cleanedNumber).toLocaleString(locale, {
    style: 'decimal',
    maximumFractionDigits: decimal ? 2 : 0,
    minimumFractionDigits: decimal ? 2 : 0
  });
};

/**
 * Calculates course duration and returns an array of hours and minutes
 * @param { Object } courseObject - A course object with lessons
 * @returns { Array } Number array of hours and minutes
 */
export const calculateCourseDuration = (courseObject) => {
  const courseDurationInSeconds = courseObject?.lessons?.reduce((prev, current) => {
    return prev + Number(current.duration);
  }, 0);
  const courseDurationInMinutes = courseDurationInSeconds / 60;
  const hours = Math.floor(courseDurationInMinutes / 60);
  const minutes = Math.floor(courseDurationInMinutes % 60);
  return { hours, minutes };
};

export function isStudent(user) {
  return user?.role?.toLowerCase() === 'student';
}

export function isAdmin(user) {
  return user?.role?.toLowerCase() === 'admin';
}

export function isInstructor(user) {
  return user?.role?.toLowerCase() === 'author';
}
