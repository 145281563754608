import { Progress } from 'antd';
import { motion } from 'framer-motion';
import Link from 'next/link';
import styled from 'styled-components';
import { secondsToHms } from 'utils/functions';

const StyledHistoryCourse = styled.div`
  height: 182px;
  width: 459px;
  background: ${(props) => props.theme.primaryText};
  border-radius: 8px;
  @media (min-width: 760px) and (max-width: 1025px) {
    //tablet
    width: 320px;
  }
  @media (max-width: 759px) {
    //phone
    width: 320px;
  }
  .history-card-content {
    padding: 16px 16px 0 16px;
    display: grid;
    height: 163px;
    grid-template-columns: 128px 1fr;
    grid-column-gap: 12px;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      grid-template-columns: 104px 1fr;
    }
    @media (max-width: 759px) {
      //phone
      padding: 8px 8px 0 8px;
      grid-template-columns: 104px 1fr;
    }
  }
  .picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  .description {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${(props) => props.theme.primaryText};
  }
  .title a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-transform: capitalize;
    color: ${(props) => props.theme.cardBackground};
    @media (max-width: 759px) {
      //phone
      font-size: 16px;
      line-height: 26px;
    }
  }
  .meta {
    color: ${(props) => props.theme.cardBackground};
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
  .progress-bar {
    height: 8px;
    padding: 0;
  }
`;

const Course = ({ course, margin, small, isCompleted }) => {
  const getProgress = (resource, completed) => {
    if (completed) {
      return 100;
    }
    if (resource.durationTrack) {
      return (
        resource.durationTrack.totalTimeWatched / resource.durationTrack.totalDuration
      ).toFixed(2);
    }
    return 0;
  };
  return (
    <StyledHistoryCourse margin={margin} small={small}>
      <div className="history-card-content">
        <Link href={`/courses/${course.url}`}>
          <a>
            <motion.img
              className="picture"
              src={course.imageUrl}
              whileHover={{
                scale: 1.03,
                transition: {
                  duration: 0.2
                }
              }}
            />
          </a>
        </Link>
        <div className="description">
          <div className="title">
            <Link href={`/courses/${course.url}`}>
              <a>{course.name}</a>
            </Link>
          </div>
          <div className="meta">
            {course.lessons?.length} {course.lessons?.length > 1 ? 'lessons' : 'lesson'} |{' '}
            {secondsToHms(course.duration)}
          </div>
        </div>
      </div>
      <div className="progress-bar">
        <Progress
          percent={getProgress(course, isCompleted)}
          strokeWidth={5}
          strokeColor={getProgress(course, isCompleted) > 0 ? '#9297a0' : '#1F9E14'}
          showInfo={false}
        />
      </div>
    </StyledHistoryCourse>
  );
};

export const HistoryLessonCard = ({ lesson, margin, small }) => (
  <StyledHistoryCourse margin={margin} small={small}>
    <div className="history-card-content">
      <Link href={`/lesson/${lesson.url}`}>
        <a>
          <motion.img
            className="picture"
            src={lesson.imageUrl}
            whileHover={{
              scale: 1.03,
              transition: {
                duration: 0.2
              }
            }}
          />
        </a>
      </Link>
      <div className="description">
        <div className="title">
          <Link href={`/lesson/${lesson.url}`}>
            <a>{lesson.name}</a>
          </Link>
        </div>
        <div className="meta">{secondsToHms(lesson.duration)}</div>
      </div>
    </div>
    <div className="progress-bar">
      <Progress
        percent={lesson.progress}
        strokeWidth={5}
        strokeColor={lesson.progress && lesson.progress > 0 ? '#9297a0' : '#1F9E14'}
        showInfo={false}
      />
    </div>
  </StyledHistoryCourse>
);

export default Course;
