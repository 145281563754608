import Banner from 'components/home/Banner';
import BookmarkedCourses from 'components/home/BookmarkedCourses';
import DashboardBanner from 'components/home/DashboardBanner';
import RecommendedCourses from 'components/home/RecommendedCourses';
import Testimonial from 'components/home/Testimonial';
import TopCategories from 'components/home/TopCategories';
// import TopLearningPaths from 'components/home/TopLearningPaths';
import TrendingCourses from 'components/home/TrendingCourses';
import Ad from 'components/shared/Ad';
import {
  ALL_CATEGORIES,
  ALL_CATEGORIES_WITH_PATHS,
  ALL_COURSES,
  ALL_LEARNING_PATHS,
  ALL_PARTNERS,
  ALL_TESTIMONIALS
} from 'lib/queries';
import { NextSeo } from 'next-seo';
import main from 'utils/mutationWrapper';

const Home = ({
  auth,
  topCategories,
  topCourses,
  // learningPaths,
  testimonials,
  trendingCourses
}) => {
  return (
    <>
      <NextSeo canonical="/" />
      {auth?.firstName === undefined ? (
        <Banner />
      ) : auth?.firstName !== undefined && auth.enrolledCourses?.length > 0 ? (
        <Banner auth={auth} />
      ) : (
        <DashboardBanner auth={auth} />
      )}
      {auth?.firstName !== undefined && auth.hasBookmarkedCourses && <BookmarkedCourses />}
      {auth?.firstName !== undefined && <TrendingCourses courses={trendingCourses} />}
      {(auth?.firstName === undefined ||
        !auth.hasRecommendedCourses ||
        !auth.hasBookmarkedCourses ||
        !auth.hasViewingHistory) && (
        <>
          <TopCategories topCategories={topCategories} topCourses={topCourses} />
          {/* <TopLearningPaths learningPaths={learningPaths} /> */}
        </>
      )}
      {auth?.firstName !== undefined && auth.hasRecommendedCourses && <RecommendedCourses />}
      {auth?.firstName === undefined && <Testimonial testimonials={testimonials} />}
      {auth?.firstName === undefined && <Ad />}
    </>
  );
};

export async function getStaticProps() {
  const { categories: apiCategories } = await main(ALL_CATEGORIES_WITH_PATHS, {
    input: {
      page: 0,
      limit: 100
    }
  });
  const { itemsList: categories } = apiCategories;

  //TODO: Replace with topCategories query
  const { categories: apiTopCategories } = await main(ALL_CATEGORIES, {
    input: {
      page: 0,
      limit: 100
    }
  });
  const { itemsList: topCategories } = apiTopCategories;

  //TODO: @abiodun Replace with apiTopCategoryCourses query
  const topCategoriesIds = topCategories.map((topCategory) => topCategory._id);

  const { courses: apiTopCategoryCourses } = await main(ALL_COURSES, {
    input: {
      page: 0,
      limit: 20
    },
    whereCondition: { categories: topCategoriesIds }
  });
  const { itemsList: topCourses } = apiTopCategoryCourses;

  //TODO: @abiodun - Replace query with TrendingCourses Query

  const { courses: apiTrendingCourses } = await main(ALL_COURSES, {
    input: {
      page: 0,
      limit: 8,
      orderBy: {
        rating: 'desc'
      }
    }
  });
  const { itemsList: trendingCourses } = apiTrendingCourses;

  const { learningPaths: apiPaths } = await main(ALL_LEARNING_PATHS, {
    input: {
      page: 0,
      limit: 8
    }
  });
  const { itemsList: learningPaths } = apiPaths;

  const { testimonials: apiTestimonials } = await main(ALL_TESTIMONIALS, {
    input: {
      page: 0,
      limit: 4
    }
  });
  const { itemsList: testimonials } = apiTestimonials;

  const { partners: apiPartners } = await main(ALL_PARTNERS, {
    input: {
      page: 0,
      limit: 4
    }
  });
  const { itemsList: partners } = apiPartners;

  return {
    props: {
      categories,
      topCategories,
      topCourses,
      learningPaths,
      testimonials,
      partners,
      trendingCourses
    },
    revalidate: 1
  };
}

export default Home;
