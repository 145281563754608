import Carousel from '@brainhubeu/react-carousel';
import Image from 'next/image';
import scrollLeftIcon from 'public/images/scroll-left.svg';
import scrollRightIcon from 'public/images/scroll-right.svg';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import CourseCard from '../shared/CourseCard';
import { StyledIntro } from '../shared/intro';

const StyledTrending = styled.div`
  padding: 80px 72px;
  @media (min-width: 320px) and (max-width: 550px) {
    padding: 50px 16px;
  }
  .courses {
    margin-top: 40px;
    @media (min-width: 320px) and (max-width: 550px) {
      margin-top: 24px;
    }
  }
  .carousel-nav {
    width: 50px !important;
    height: 50px !important;
    min-width: initial !important;
    min-height: initial !important;
    max-width: initial !important;
    max-height: initial !important;
    cursor: pointer;
    position: initial !important;
  }
  .BrainhubCarousel .BrainhubCarousel__trackContainer {
    margin: 0 -50px;
    margin: 0 -50px 0 0;
  }
  .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft,
  .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowRight {
    z-index: 100;
  }
`;

const TrendingCourses = ({ courses }) => {
  const [slidesPerPage, setSlidesPerPage] = useState(4);
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1280);

  useEffect(() => {
    if (window) {
      const handleResize = () => setWidth(window.innerWidth);
      if (width > 1320) {
        setSlidesPerPage(4);
      } else if (width > 1050 && width <= 1320) {
        setSlidesPerPage(3);
      } else if (width > 700 && width <= 1050) {
        setSlidesPerPage(2);
      } else {
        setSlidesPerPage(1);
      }
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  });

  return (
    <StyledTrending>
      <StyledIntro centered>
        <div className="main-title">Trending Courses</div>
        <div className="sub-title">What others are currently watching</div>
      </StyledIntro>
      <div className="courses">
        <Carousel
          slidesPerPage={slidesPerPage}
          autoPlay={5000}
          infinite
          animationSpeed={2000}
          arrowLeft={
            courses && courses.length > slidesPerPage ? (
              <Image alt="Scroll Left" className="carousel-nav" src={scrollLeftIcon} />
            ) : null
          }
          arrowRight={
            courses && courses.length > slidesPerPage ? (
              <Image alt="Scroll Right" className="carousel-nav" src={scrollRightIcon} />
            ) : null
          }
          addArrowClickHandler>
          {courses &&
            courses.map((course) => (
              <CourseCard id={course._id} key={course._id} course={course} centered />
            ))}
        </Carousel>
      </div>
    </StyledTrending>
  );
};

export default TrendingCourses;
