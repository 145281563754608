import NotFound from 'components/shared/NotFound';
import { ALL_COURSES } from 'lib/queries';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import main from 'utils/mutationWrapper';

import CourseCard from '../shared/CourseCard';
import { StyledIntro } from '../shared/intro';

const StyledRecommendedCourses = styled.div`
  padding: 80px 72px;
  @media (min-width: 320px) and (max-width: 550px) {
    padding: 50px 16px;
  }
`;

const StyledCourseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 32px;
`;

const RecommendedCourses = () => {
  const [recommendedCourses, setRecommendedCourses] = useState([]);

  const fetchRecommendedCourses = useCallback(async () => {
    // TODO: @abiodun - Swap for actual recommended courses query when API is ready
    const { courses: apiRecommendedCourses } = await main(ALL_COURSES, {
      input: {
        page: 0,
        limit: 8,
        orderBy: {
          rating: 'desc'
        }
      }
    });
    const { itemsList: bookmarks } = apiRecommendedCourses;
    setRecommendedCourses(bookmarks);
  }, []);

  useEffect(() => {
    fetchRecommendedCourses();
  }, []);

  return (
    <StyledRecommendedCourses>
      <StyledIntro centered>
        <div className="main-title">Recommended Courses for You</div>
      </StyledIntro>
      {recommendedCourses.length === 0 ? (
        <NotFound title="Please, check back. We're working on your recommendations" />
      ) : (
        <StyledCourseGrid>
          {recommendedCourses.map((activeCourse) => (
            <CourseCard course={activeCourse} key={activeCourse._id} centered />
          ))}
        </StyledCourseGrid>
      )}
    </StyledRecommendedCourses>
  );
};

export default RecommendedCourses;
