import { gql } from 'graphql-request';

export const CREATE_PAYMENT = gql`
  mutation createPayment($input: PaymentInput!) {
    createPayment(input: $input) {
      payment_id
      amount
      amountToPay
      coupon {
        _id
        discountRate
        expiryDate
      }
    }
  }
`;

export const CREATE_RECURRING_PAYMENT = gql`
  mutation createRecurringPayment($input: RecurrentPaymentInput!) {
    createRecurringPayment(input: $input) {
      payment_id
      amount
      amountToPay
      coupon {
        _id
        discountRate
        expiryDate
      }
    }
  }
`;

export const CREATE_TEAM_CAPACITY_UPGRADE_PAYMENT = gql`
  mutation createTeamCapacityUpgradePayment($input: TeamCapacityUpgradePaymentInput!) {
    createTeamCapacityUpgradePayment(input: $input) {
      payment_id
      amount
      amountToPay
      coupon {
        _id
        discountRate
        expiryDate
      }
    }
  }
`;

export const VERIFY_PAYMENT = gql`
  mutation verifyPayment($input: VerifyPaymentInput!) {
    verifyPayment(input: $input) {
      _id
      payment_id
    }
  }
`;

export const CREATE_ONE_TIME_PAYMENT = gql`
  mutation createOneTimePayment($input: CoursePaymentInput!) {
    createOneTimePayment(input: $input) {
      payment_id
      amount
      amountToPay
      coupon {
        _id
        discountRate
        expiryDate
      }
    }
  }
`;

export const POST_REVIEW = gql`
  mutation createReview($input: ReviewInput!) {
    createReview(input: $input) {
      _id
      description
      rating
      user {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_REVIEW = gql`
  mutation updateReview($input: ReviewInput!, $id: String!) {
    updateReview(input: $input, id: $id) {
      _id
      description
      rating
      user {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const POST_COMMENT = gql`
  mutation createReviewComment($input: ReviewCommentInput!) {
    createReviewComment(input: $input) {
      _id
      comment
      isAuthor
    }
  }
`;

export const POST_COMMENT_REPLY = gql`
  mutation createCommentReply($input: CommentReplyInput!, $commentId: String!) {
    createCommentReply(input: $input, commentId: $commentId) {
      _id
      reply
      isAuthor
    }
  }
`;

export const DELETE_COMMENT_REPLY = gql`
  mutation deleteCommentReply($id: String!) {
    deleteCommentReply(id: $id) {
      _id
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteReviewComment($id: String!) {
    deleteReviewComment(id: $id) {
      _id
    }
  }
`;

export const TRACK_LESSON = gql`
  mutation trackLesson($input: TrackedLessonInput!) {
    trackLesson(input: $input) {
      _id
      isCompleted
      currentPosition
    }
  }
`;

export const UPDATE_USER_WATCH_HISTORY = gql`
  mutation updateUserWatchHistory($input: WatchHistoryInput!) {
    updateUserWatchHistory(input: $input) {
      _id
    }
  }
`;

export const ADD_USER_BENEFICIARY = gql`
  mutation addBankDetail($input: CreateBankDetailInput!) {
    addBankDetail(input: $input) {
      _id
      accountName
      accountNumber
      bankCode
      recipientCode
      isPrimary
      userId
    }
  }
`;

export const DELETE_USER_BENEFICIARY = gql`
  mutation deleteBankDetail($input: DeleteBankDetailInput!) {
    deleteBankDetail(input: $input) {
      _id
    }
  }
`;

export const CREATE_WITHDRAWAL = gql`
  mutation createWithdrawal($input: CreateWithdrawalInput!) {
    createWithdrawal(input: $input) {
      _id
      amount
    }
  }
`;

export const VERIFY_ONE_TIME_PAYMENT = gql`
  mutation verifyOneTimePayment($input: VerifySinglePaymentInput!) {
    verifyOneTimePayment(input: $input) {
      _id
      payment_status
    }
  }
`;

export const VERIFY_RECURRENT_PAYMENT = gql`
  mutation verifyRecurrentPayment($input: VerifyRecurrentPaymentInput!) {
    verifyRecurrentPayment(input: $input) {
      _id
      payment_status
    }
  }
`;

export const VERIFY_TEAM_CAPACITY_UPGRADE_PAYMENT = gql`
  mutation verifyTeamCapacityUpgradePayment($input: VerifyRecurrentPaymentInput!) {
    verifyTeamCapacityUpgradePayment(input: $input) {
      _id
      payment_status
    }
  }
`;

export const ADD_MEMBER = gql`
  mutation addMember($input: UserEmail!) {
    addMember(input: $input) {
      _id
      members
      current_members
    }
  }
`;

export const REMOVE_MEMBER = gql`
  mutation removeMember($input: UserEmail!) {
    removeMember(input: $input) {
      _id
    }
  }
`;

export const GET_AMOUNT_TO_PAY = gql`
  mutation getAmountToPay($input: ExpandUsers!) {
    getAmountToPay(input: $input) {
      amount_to_pay
      message
    }
  }
`;

export const CANCEL_SUBCRIPTION = gql`
  mutation cancelSubscription {
    cancelSubscription {
      _id
    }
  }
`;

export const INCREASE_TEAM_SIZE = gql`
  mutation increaseTeamCapacity {($input:  ExpandUsers!) {
    increaseTeamCapacity(input: $input) {
      _id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      _id
      firstName
      lastName
      imageUrl
      bio
      website
      facebookUrl
      twitterUrl
      instagramUrl
      linkedinUrl
    }
  }
`;
export const PASSWORD_RESET_REQUEST = gql`
  mutation passwordResetRequest($input: PasswordResetInput!) {
    passwordResetRequest(input: $input) {
      _id
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!, $id: String!) {
    resetPassword(input: $input, id: $id) {
      _id
    }
  }
`;
export const UPDATE_EMAIL = gql`
  mutation updateEmail($input: EmailInput!) {
    updateEmail(input: $input) {
      email
    }
  }
`;
export const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: ChangePasswordInput!) {
    updatePassword(input: $input) {
      message
    }
  }
`;

export const SIGN_IN_WITH_GOOGLE = gql`
  mutation signInWithGoogle($input: SignInWithGoogleInput!) {
    signInWithGoogle(input: $input) {
      _id
      firstName
      lastName
      email
      token
    }
  }
`;

export const REQUEST_A_DEMO = gql`
  mutation createDemoRequest($input: CreateDemoRequestInput!) {
    createDemoRequest(input: $input) {
      _id
      firstName
      lastName
      company
      country
      info
      status
    }
  }
`;

export const SEND_TEAM_SUBSCRIPTION_INVITE = gql`
  mutation inviteTeamMember($input: InviteTeamMemberInput!) {
    inviteTeamMember(input: $input) {
      _id
    }
  }
`;

export const ACCEPT_TEAM_INVITATION = gql`
  mutation acceptTeamInvitation($input: AcceptTeamInvitationInput!) {
    acceptTeamInvitation(input: $input) {
      _id
      declinedAt
      canceledAt
      expiryDate
    }
  }
`;

export const DECLINE_TEAM_INVITATION = gql`
  mutation declineTeamInvitation($input: DeclineTeamInvitationInput!) {
    declineTeamInvitation(input: $input) {
      _id
      declinedAt
      canceledAt
      expiryDate
    }
  }
`;

export const CANCEL_TEAM_INVITATION = gql`
  mutation cancelTeamInvitation($input: CancelTeamInvitationInput!) {
    cancelTeamInvitation(input: $input) {
      _id
      declinedAt
      canceledAt
      expiryDate
    }
  }
`;

export const PAY_WITH_SAVED_CARD = gql`
  mutation payWithSavedCard($input: PaymentWithSavedCardInput!) {
    payWithSavedCard(input: $input) {
      reference
    }
  }
`;

export const VALIDATE_COUPON_CODE = gql`
  mutation validateCouponCode($input: ValidateCouponInput!) {
    validateCouponCode(input: $input) {
      isValid
      validityIssue
      coupon {
        _id
        discountRate
        code
      }
    }
  }
`;

export const REMOVE_TEAM_MEMBER = gql`
  mutation removeMember($input: RemoveTeamMemberInput!) {
    removeMember(input: $input) {
      _id
    }
  }
`;

export const CREATE_BLOG_COMMENT = gql`
  mutation createBlogComment($input: CreateBlogCommentInput!) {
    createBlogComment(input: $input) {
      _id
      fullname
      email
      body
      articleId
      commentId
      _createdAt
      _updatedAt
    }
  }
`;
