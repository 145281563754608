import styled from 'styled-components';

import Banner from './Banner';

const StyledDashboardBanner = styled.div`
  width: 100%;
  padding: 0 40px;
  height: 356px;
  background: ${({ theme }) =>
    `linear-gradient(270deg, ${theme.primaryLink} 2.58%, ${theme.headerBackground} 119.34%)`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 16px;
  @media (min-width: 320px) and (max-width: 550px) {
    grid-row-gap: 8px;
    padding: 0 20px;
  }
  .title {
    font-family: 'DM Serif Display', serif;
    font-style: normal;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: ${({ theme }) => theme.primaryText};
    font-weight: normal;
    @media (min-width: 320px) and (max-width: 550px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .desc {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: ${({ theme }) => theme.primaryText};
    opacity: 0.8;
    font-weight: 500;
    @media (min-width: 320px) and (max-width: 550px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const DashboardBanner = ({ auth }) => {
  if (!auth) {
    return <Banner />;
  } else {
    return (
      <StyledDashboardBanner>
        <div className="title">
          Hi, {auth.firstName && auth.firstName.replace(/^./, auth.firstName[0].toUpperCase())}.
          Welcome!
        </div>
        <div className="desc">
          Start your journey now! Upgrade your skills with courses from industry experts.
        </div>
      </StyledDashboardBanner>
    );
  }
};

export default DashboardBanner;
